.qty-box {
  width: 4rem;
  border: 1px black solid;
}
table.fixed .qty-box{
  width: 100%;
  max-width: 4rem;;
}
.seprator-color {
  color: #000000;
  margin-right: 5px;
}

.ui.grid.product-grid > .column:not(.row) {
  width: 20%;
}
.grid-columns{
  display: flex;
  flex-wrap:wrap;
  justify-content: space-between;
  overflow-wrap: word-break;
}
.card .content{
  display: flex;
  flex-direction: column;
  align-items: center;
}
#Assortmentgrid_segment div.content {
  padding-bottom: 0em !important;
}
.grid-column{
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}
.ui.card .card_item{
  padding: 0.71rem 0.71rem 0.71rem 0.93rem;
  display: flex;
  flex-direction: column;
  column-span: all;
}
.grid-header{
  position: absolute;
  top: 0.78rem;
  left: 0.78rem;
  right: 0.78rem;
  display: flex; 
  justify-content:space-between;
  align-items:center; 
  margin: auto;
}
#vendor-width {
  padding-left: 0;
}

.header{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
}
.grid_title{
  height: 5rem;
  text-overflow: ellipsis;
  display: block;
  display: -webkit-inline-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow:hidden;
  text-overflow: ellipsis;
}
.ui.six.cards {
  margin-left: 0em !important;
  margin-right: 0em !important;
}
.ui.cards {
  margin: 0 !important;
}
.ui.six.cards>.card {
  width: calc(16.66666667% - 0.5em) !important;
  margin-left: .25em !important;
  margin-right: .25em !important;
}
.grid-image{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
}
.grid-input{
  line-height: 1rem;
}
.ui.small.image.grid_image{
  width: 75px !important;
  height: 100% !important;
  object-fit: contain;
}
.product-grid .compare-n-fav-compact {
  font-size: 0.8rem;
}

.First_level_details-grid-compact{
  font-size: 0.9rem;
  line-height: 2rem;
  font-family: HelveticaNeueBold;
}
.ui.card, .ui.cards>.card{
  min-height: max-content !important;
}
.card_footer{
  margin-bottom: 0.93rem;
  margin-top: 0.93rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.grid_button-compact{
  width: cal(60% + 2px) !important;
  text-align: center;
}
.ui.table.grid-table{
  display: flex;
  justify-content: center;
  align-items: center;
}
.ui.table.grid-table tr td:nth-child(2n+1){
  padding-right: 0.4rem !important;
}
.ui.table.grid-table tr{
  padding-bottom: 0.5rem !important;
  border: none !important;
}
.ui.table.grid-table tr td:nth-child(2n+1){
  white-space: nowrap;
}
.ui.table.grid-table tr td:nth-child(2n){
  word-break: break-word;
  line-height: 1.1;
}
.card_fields > .ui.grid {
  align-items: baseline;
}
.card_fields > .ui.grid > .column:not(.row) {
  padding-top: 0;
  padding-bottom: 0;
  overflow-wrap: break-word;
}
#cart-clip{
  padding-bottom: 3rem !important;
}
.ui.six.cards::after{
  content:'';
  flex:auto;
}
#ds_gridListing .card_item .card_img {
  min-height: 9rem;
}
#ds_gridListing .grid_title {
  height: auto;
}
/* breakpoint */
@media only screen and (min-width: 1366px) and (max-width: 1440px){
  .ui.six.cards>.card {
    width: calc(20% - 0.6em) !important;
    margin-left: 0.25em !important;
    margin-right: 0.25em !important;
  }
  @supports (-ms-ime-align: auto) {
    .ui.six.cards>.card{
      width: calc(20% - 0.6em) !important;
      margin-left: 0.25em !important;
      margin-right: 0.25em !important;
    }
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1366px){
  .ui.six.cards>.card {
    width: calc(25% - 0.5em) !important;
    margin-left: 0.25em !important;
    margin-right: 0.25em !important;
  }
  @supports (-ms-ime-align: auto) {
    .ui.six.cards>.card{
      width: calc(25% - 0.6em) !important;
      margin-left: 0.25em !important;
      margin-right: 0.25em !important;
    }
  }
 }
@media only screen and (max-width:1240px) and (min-width:1024px){
  .ui.six.cards>.card {
    width: calc(25% - 0.5em) !important;
    margin-left: .25em !important;
    margin-right: .25em !important;
  }
  @supports (-ms-ime-align: auto) {
    .ui.six.cards>.card{
      width: calc(25% - 0.6em) !important;
      margin-left: 0.25em !important;
      margin-right: 0.25em !important;
    }
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px){
  .ui.six.cards>.card{
    width: calc(50% - 0.5em) !important;
    margin-left: 0.25em !important;
    margin-right: 0.25em !important;
  }
    @supports (-ms-ime-align: auto) {
      .ui.six.cards>.card{
        width: calc(50% - 0.6em) !important;
        margin-left: 0.25em !important;
        margin-right: 0.25em !important;
      }
    }
}

@media only screen and (max-width: 768px) and (min-width: 375px){
  .ui.six.cards>.card {
    width: calc(50% - 0.5em) !important;
    margin-left: .25em !important;
    margin-right: .25em !important;
  }
  @supports (-ms-ime-align: auto) {
    .ui.six.cards>.card{
      width: calc(50% - 0.6em) !important;
      margin-left: 0.25em !important;
      margin-right: 0.25em !important;
    }
  }
}

/* iPads (portrait and landscape) ----------- */

@media only screen 
and (min-width : 768px) 
and (max-width : 1024px) 
and (orientation : portrait) { 
    .ui.grid.product-grid > .column:not(.row) {
    width: 50%;
  }
  .ui.six.cards>.card {
    width: calc(33.33% - 0.5em) !important;
    margin-left: 0.25em;
    margin-right: 0.25em;
  }
  @supports (-ms-ime-align: auto) {
    .ui.six.cards>.card{
      width: calc(33.33% - 0.6em) !important;
      margin-left: 0.25em !important;
      margin-right: 0.25em !important;
    }
  }
 }

 @media only screen 
and (min-width : 768px) 
and (max-width : 1024px) 
and (orientation : landscape) { 
    .filter-open .ui.grid.product-grid > .column:not(.row) {
    width: 50%;
  }
  .ui.six.cards>.card {
    width: calc(33.33% - 0.5em) !important;
    margin-left: 0.25em;
    margin-right: 0.25em;
  }
  @supports (-ms-ime-align: auto) {
    .ui.six.cards>.card{
      width: calc(33.33% - 0.6em) !important;
      margin-left: 0.25em !important;
      margin-right: 0.25em !important;
    }
  }
 }

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .ui.grid.product-grid > .column:not(.row) {
    width: 50%;
  }
  .grid-column{
    flex: 0 0 100%;
    margin-left: 0;
  }
  .ui.six.cards>.card {
    width: calc(100% - 0.5em) !important;
    margin-left: 0.25em;
    margin-right: 0.25em;
  }
  @supports (-ms-ime-align: auto) {
    .ui.six.cards>.card{
      width: calc(100% - 0.6em) !important;
      margin-left: 0.25em !important;
      margin-right: 0.25em !important;
    }
  }
}

@media only screen and (max-width: 768px){
  .ui.menu.pagination-padding-mobile .item{
    padding-right: 0.8em;
    padding-left: 0.8em;
  }
}
