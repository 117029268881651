@font-face {
    font-family: arialBold;
    src: url('../../fonts/G_ari_bd.TTF');
  }
@font-face {
    font-family: AntonioBold;
    src: url('../../fonts/Antonio-Bold.ttf');
}
@font-face {
    font-family: AntonioRegular;
    src: url('../../fonts/Antonio-Regular.ttf');
}
.retailerWrapper {
    width: 90%;
    max-width: 1180px;
    margin: 2rem auto;
    display: inline-block;
}
.retailerWrapper .ui.grid {
    margin: 1rem auto;
}
.retailerWrapper .ui.tabular.menu {
    display: flex;
    flex-wrap: wrap;
}
.retailerWrapper .ui.tabular.menu .item {
    background: #ebebeb;
    color: #222;
    border: 0;
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
    text-transform: uppercase;
    font-family: arialBold;
    border-radius: 3px;
}
.retailerWrapper .ui.tabular.menu .active.item {
   background-color: #bebebe;
}
.retailerWrapper .menu {
    background: none !important;
}
.ui.segment.retailerTabContent {
    background: none;
    border: 0;
    border-bottom: 2px solid #878787;
    border-radius: 0;
    margin: 0;
}
.retailerTabContent p {
    color: #222;
    font-size: 1rem;
    
}
.retailerTabContent h4 {
    color: #222;
    font-family: AntonioBold;
    font-size: 1.5rem;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
}
.retailerTabContent span{
    color: #C8102E;
    font-family: arialBold;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    display: inline-block;
}
.retailerTabContent .rightTagBox{
    background-color: #ebebeb;
    color: #222;
    
}
a.linkSlugBtn {
    position: absolute;
    right: 1rem;
    top: 1rem;
    background: #bebebe;
    color: #222;
    padding: 0.5rem;
    font-size: 1rem;
    border-radius: 3px;
}
.retailerPagination {
    display: flex;
    justify-content: center;
}
.retailerBanner {
    height: 10rem;
    background-color: #C8102E;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: #fff;
}
.retailerBanner h2 {
    font-family: AntonioBold;
    text-transform: uppercase;
    font-size: 4rem;
}
.retailerBanner p {
    color: #fff;
    font-size: 1.2rem;
}
.firstTabContent a.linkSlugBtn {
    top: 13rem;
}
a.linkSlugBtnMobile {
    top: 3rem !important
}