.categories .view-details-btn {
  margin-left: 5px;
}

.categories .input-select {
  padding-right: 0.2rem;
  margin-right: 0.5rem;
}

.categories .bread-crumb {
  padding-bottom: 0.5rem;
}

.highlight {
  border: 2px #168c87 solid !important;
}

.highlight-disclaimer {
  margin-left: 1rem;
}

.right-justified {
  margin-left: auto;
}

#grid_segment_assort {
  height: calc(100% - 2.25rem);
  position: static;
}

#grid_segment {
  height: calc(100% - 0.5rem);
  position: static;
}

.listing-header {
  display: flex;
  justify-content: space-between;
  position: relative;
}

#clear_quantities {
  cursor: pointer;
  color: #4183c4;
  text-decoration: underline;
}

.product-grid #grid_segment {
  margin: 0;
}

.comparison-field-mobile {
  background-color: #f2f2f2;
  padding: 0;
  font-size: 1rem;
  margin: 0;
  font-weight: bold;
}

.mobilepacks {
  margin: 0.5rem !important;
  padding: 0 !important;
}

.grid-mobilecmp {
  padding-top: 0.2rem !important;
  margin: 0px !important
}

.remove-all-span.mob {
  padding-left: 0rem !important;
  margin-bottom: 1rem;
}

.product-field {
  font-weight: bold;
}

.ui.grid>[class*="seven wide"].column.mobilepacks {
  width: 48% !important;
  padding: 0 0px !important;
  margin: 0 2px !important;
}

.mobilepacks:nth-child(2) .comparison-field-mobile span {
  visibility: hidden;
}

.mobindicator {
  height: 6rem;
  max-height: 6rem;
}

.mobcompprice {
  height: 2rem;
  max-height: 2rem;
  margin: 0.4rem 0;
}

.cmpre-green {
  color: #388e3c;
}


.add-to-catalog-button {
  margin-bottom: 0.1rem !important;
  font-weight: bolder;
}

.disabledMessage {
  color: #c8102e;
  font-size: 0.8rem;
  padding: 0.25rem 0;
  text-align: center;
  max-width: 8.3rem;
}

.fluid.button+.disabledMessage {
  max-width: inherit;
}

.place-order-btn+.disabledMessage {
  max-width: inherit;
  padding-right: 0.45rem;
  text-align: right;
}

.product-grid .disabledMessage {
  text-align: center;
}
.compare-container .product-field.long-desc {
  overflow: hidden;
}
.directShipHeader {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.dropShipBtnWrapper {
  min-width: 280px;
}
.directShipQtyMsg {
  margin-bottom: 1rem;
  text-align: center;
}
.directShipTitle {
  font-size: 1rem;
}
.directShipQtyMsg strong {
  color: #c8102e;
}
.directShipQtyMsg p {
  font-family: HelveticaNeueBold;
  font-size: 1.1rem;
  margin-bottom: 0;
}
.directShipQtyMsg > span {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: #c8102e
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .row1 {
    height: 18rem;
  }

  .vendor-span {
    font-size: 0.8rem;
  }

  .compareProductName {
    font-size: 1rem;
    font-weight: bold;
  }

  .categories .input-select {
    border: 0.1rem;
    border-radius: 0rem;
    background-color: #ffffff;
  }

  #list_icon {
    padding: 0 1.25rem 0 0.5rem;
  }

  .iconPopUp {
    background-color: red;
  }

  .products_padding>select {
    margin-left: 0.5rem;
  }

  .sortByCategories {
    margin-top: 1rem;
  }

  .product-field {
    font-weight: normal;
  }

  .listing-header-left {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .row1 {
    height: 18rem;
  }

  .vendor-span {
    font-size: 0.8rem;
  }

  .compareProductName {
    font-size: 1rem;
    font-weight: bold;
  }

  .categories .input-select {
    border: 0.1rem;
    border-radius: 0rem;
    background-color: #ffffff;
  }

  .product-field {
    font-weight: normal;
  }

  .listing-header {
    flex: 1 1 auto;
  }
}

@media print {
  html {
    font-size: 16px;
  }

  .listingDescription {
    font-size: 1.2rem;
  }
}

@media (max-width:768px) {
  .directShipQtyMsg p {
    font-size: 0.9rem;
  }
  .directShipQtyMsg strong {
    display: block;
  }
  .dropShipBtnWrapper {
    margin-bottom: 0.5rem;
  }
}