
.ui.input>input {
  border-color: black !important;
  border-radius: unset !important;
}

.textarea {
  border-color: black !important;
  overflow: auto !important;
}

.ui.red.button {
  border-radius: unset;
}
.askfield{
  padding-left:0.5rem;
  width:23rem;
  height:1.5rem;
  line-height: 1.5rem;
  background: #fff;
  border-color: black;
  border: 1px solid #000;
}
.autoform{
  padding-left:2rem;
  width:25rem;
  height:1rem;
}
.askTitle{
  width:9.5rem;
}
.askform{
  padding-top:1rem;
}
.autoFormText {
  height: auto;
  text-align: left;
  width: 35.5rem;
  font-weight: bolder;
}
.asksubmit{
  width: 34.5rem;
  flex-direction: row-reverse;
}
.commentask{
  margin-left:2rem;
  width:23rem;
  height:9rem;
  border-color:black;        
}
.ui.selection.dropdown .menu>.item.reasonMenu{
  background-color: white;
  font-size: 1rem;
  padding: 0.25rem !important;
}
.activeMenu{
  color: white;
}
.reasonDropdown{
  margin-left:2rem;
  width:23rem;
  height:1.5rem;
  border-color:black !important;        
}
.askback{
  padding-top:1rem;
  padding-bottom:1rem;
  width: 34.5rem;
  text-align: right;
}
.contactlabel{
  width:9.5rem;
}
.merchantdetails{
  margin-left: 7.8rem;
}
.inventorydetails{
  display: block;
  margin-left:11.7rem;
}
.askcheckbox{
  font-weight:bolder;
  flex:auto;
  margin-top: 0.7rem;
}
@media screen and (max-width: 767px) {
  .askfield{
    width:15rem;
  }
  .askTitle {
    width: 5.5rem;
}
.commentask {
  width: 15rem;
  margin-left: 2rem;
}
.askback{
  width: 24rem;
}
  
.merchantdetails {
  margin-left: 2.8rem;
  font-size: 12px;
}
.inventorydetails {
  margin-left: 6.7rem;
}
.asksubmit {
  width: 22.5rem;
}
.autoform {
  padding-left: 3rem;
}
}