@charset "UTF-8";
body {
  margin: 0;
  padding: 0;
  font-family: HelveticaNeue;
  background-color: #f2f2f2;
}
.displayBlock{
  display: block !important;
}
.displayNone{
  display: none !important;
}
.displayFlex {
  display: flex;
}
.displayInlineFlex {
  display: inline-flex;
}
.impulseMobileDetail {
  display: block;
}
.displayInline {
  display: inline;
}
.pdp_details-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pagination {
  background: none !important;
  border-top: none !important;
  border-bottom: none !important;
  border-left: none !important;
  box-shadow: none !important;
}

.pagination .item {
  background: none !important;
  color: #176db7 !important;
  text-decoration: underline !important;
  font-weight: bolder !important;
}

.pagination .active.item {
  color: #000000 !important;
  text-decoration: none !important;
}
.pagination .item:before {
  display: none;
}
.pagination .item[type='nextItem']::before {
  position: unset !important;
  margin-bottom: -0.3rem;
  display: block;
  background: none !important;
  color: #176db7 !important;
  text-decoration-color: #176db7 !important;
}

.disabled{
  cursor: not-allowed;
  color: #919191;
  text-decoration: none;
}

.styled-cb{
  -webkit-appearance: none;
}
.styled-cb + label{  
  height: 1.2rem;
  width: 1.2rem;
  cursor: pointer;
  background-color: #ffffff;
  border: 1px solid #000000;
  color: #000000;
  font-weight: bold;
}
.styled-cb:checked + label:after{
  content: '✔';
}
.styled-cb:not(:checked) + label:after{
  content:'';
}
.authLoader {
  position: fixed;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  background: #fff;
  width: 100%;
  top: 0;
  z-index: 5000;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #c8102e;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
