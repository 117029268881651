.ui.segment.product_card-compact{
    position: relative;
    margin: 0 0;
    padding: 0.28rem 0.71rem;
}

.ui.grid>.column:not(.row).card-padding{
    padding: 0;
}
.plp_column-display{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.card-position{
    margin-bottom: 0.643rem;
    display: flex;
    flex: 0 0 10%;
}
.one-column-display{
    display: flex;
    flex: 0 0 3%;
}

.view_button-compact-visible{
    display: inline-block;
    padding: 0;
    box-sizing: border-box;
    font-family: HelveticaNeueBold;
    font-size: 0.785rem;
    min-width: 5rem;
    line-height: 1.57rem;
    text-decoration: underline;
    color: #2b77f2;
    cursor: pointer;
}
.view_button-compact-notvisible{
    display: none;
}

.listingDescription-compact{
    font-size: 0.93rem !important;
   line-height: 1.28rem;
    text-align:left;
    max-width: 92%;
    width: 100%;
}
.plp_table-compact-custom .ui.table tr td:first-child{
    border-left: 1px rgba(34,36,38, 0.15) solid;
    
}
.plp_table-compact-custom .ui.table tr td:nth-child(2){
    min-width: 4rem !important;
    border-right: 1px rgba(34,36,38,0.15) solid;
}
.plp_table-compact-custom .ui.table tr td:nth-child(4){
    min-width: 4rem !important;
    border-right: 1px rgba(34,36,38,0.15) solid;
}
.plp_table-compact-custom .ui.table tr td:nth-child(6){
    min-width: 4rem !important;
    border-right: 1px rgba(34,36,38,0.15) solid;
}

.plp_table-compact-default .ui.table tr td:nth-child(2){
    min-width: 6rem !important;
    border-right: 1px rgba(34,36,38,0.15) solid;
}
.plp_table-compact-default .ui.table tr td:nth-child(4){
    min-width: 6rem !important;
    border-right: 1px rgba(34,36,38,0.15) solid;
}

.plp_table-compact-custom .ui.table tr td:last-child{
    padding-right: 0 !important;
    border: none;
}
.plp_table-compact-custom .ui.table tr td:nth-child(2n+1){
    padding-left: 0.15rem !important;
}
.plp_table-compact-custom .ui.table tr td:nth-child(2n){
    padding-left: 0.3rem !important;
}
.plp_table-compact-custom .ui.table tr td:last-child{
    padding-right: 0 !important;
    border: none  !important;
}
.plp_table-compact-default .ui.table tr td:first-child{
    border-left: 1px rgba(34,36,38, 0.15) solid;
}

/* .plp_table-compact-default .ui.table tr td:nth-child(2n){
    padding-right: 3.14rem !important;
} */
.plp_table-compact-default .ui.table tr td:last-child{
    padding-right: 0 !important;
    border: none !important;
    min-width: 5rem;
}

.vendor-span-compact{
    font-size: 0.85rem;
    line-height: 1.57rem;
    color: #176db7;
    overflow: hidden;
    width: 80%;
    margin:auto;
    text-overflow: ellipsis;
}

.indicators-div-compact{
    font-size: 0.85rem;
    line-height:2rem;
}

.indicator_icon-compact{
    font-size: 0.85rem;
    line-height: 1rem;
}

.icon-display{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.image-icon-display{
    display: flex;
}
.image-icon-display>span:first-child{
    margin-right: 0.3rem;
}
.image-icon-display>span:last-child{
    margin-right: 1.78rem;
}
.ui.table.table-compact{
    font-weight: 1000;
}

tbody.table_body-compact {
    font-size: 0.78rem;
}

.two-column-display{
    display: flex;
    justify-content: space-between;
    flex: 1 0 100%;
    align-items: center;
}

.one-column-display{
    display: flex;
    flex-direction: column;
}

.ui.grid>.column:not(.row).grid-compact-padding{
    padding: 0 !important;
}

.First_level_details-compact{
    font-size: 0.78rem !important;
    line-height: 1.5rem !important;
}
.row-display{
    display: flex;
    flex-direction: column;
    margin-left: 0.57rem;
    width: 100%;
}
.plp_header{
    display: flex;
    max-width: 100%;
    width: 100%;
    margin: auto;
    align-items: flex-end;
    justify-content: space-between;
}
.plp_footer{
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    bottom: 1px;
}
.plp_add_to_cart-compact{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
}
.compare-n-fav-compact{
    display: flex;
    align-items: center;
}

.ui.checkbox.checkbox-compact{
    display: flex;
    align-items: center;
}

.input-compact{
    padding: 0.1rem 0.3rem;
    font-size: 100%;
}

input.input-compact:-webkit-autofill {
    -webkit-background-clip: text;
}

.compare-compact{
    padding-right: 0.2rem;
    font-size: 0.85rem;
    line-height: 1.5rem;
    font-weight: 700;
}
.red.ui.button.plp_button-compact{
    padding: 0.4rem 1rem;
    font-size: 0.93rem;
    line-height: 1.15rem;
    width: 100%;
    margin-right: 0;
}

.disabledMessage-compact{
    margin-bottom: 0;
}
.product_title-compact{
    font-size:1.14rem;
    line-height: 1.71rem;
    text-decoration: underline;
    color: #2b77f2;
}
.one-column-display-invisible{
    display:none;
}

.plp_text-custom{
    flex : 1;
    margin-bottom: 39px;
}

.plp_text-default{
    flex : 1;
    margin-bottom: 42px;
}

@media only screen and (min-width: 1097px){
    .plp_table-compact-custom .ui.table tr td:nth-child(2n){
        padding-left: 0.57rem !important;
    }
    .plp_table-compact-custom .ui.table tr td:nth-child(2){
        min-width: 8.42rem !important;
        border-right: 1px rgba(34,36,38,0.15) solid;
    }
    .plp_table-compact-custom .ui.table tr td:nth-child(4){
        min-width: 6.03rem !important;
        border-right: 1px rgba(34,36,38,0.15) solid;
    }
    .plp_table-compact-custom .ui.table tr td:nth-child(6){
        min-width: 4.3rem !important;
        border-right: 1px rgba(34,36,38,0.15) solid;
    }

    .plp_table-compact-custom .ui.table tr td:first-child{
        border-left: 1px rgba(34,36,38, 0.15) solid;
        
    }
    .plp_table-compact-custom .ui.table tr td:last-child{
        padding-right: 0 !important;
        border: none;
    }
    .plp_table-compact-default .ui.table tr td:nth-child(2n){
        padding-left: 0.57rem !important;
    }
    .plp_table-compact-default .ui.table tr td:nth-child(2){
        min-width: 10rem !important;
        border-right: 1px rgba(34,36,38,0.15) solid;
    }
    .plp_table-compact-default .ui.table tr td:nth-child(4){
        min-width: 4rem !important;
        border-right: 1px rgba(34,36,38,0.15) solid;
    }
    .plp_table-compact-default .ui.table tr td:first-child{
        border-left: 1px rgba(34,36,38, 0.15) solid;
    }
  .plp_table-compact-default .ui.table tr td:nth-child(2n+1){
      padding-left: 0.15rem !important;
  }
  .plp_table-compact-default .ui.table tr td:last-child{
      padding-right: 0 !important;
  }
  .listingDescription-compact{
      max-width: 80%;
      width:100%;
  }
  .one-column-display{
      display: flex;
      flex: 0 0 4.54%;
  }
}

.restrictionMsg {
    font-family: HelveticaNeueBold;
    color: #cb1e00;
    text-align: left;
    margin-bottom: -0.3rem;
}
.restrictionMsgcolor {
    background-color: #f9e8a2;
}
.tooltip {
    position: relative;
    display: inline-block;
}
.tooltip .tooltiptext {
    visibility: hidden;
    width: 590px;
    background-color: #f9e8a2;
    color: #cb1e00;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
  
    /* Position the tooltip */
    width: 250px;
    top: 100%;
    left: 50%;
    margin-left: -216px;
    position: absolute;
    z-index: 1;
}
.tooltip:hover .tooltiptext {
    visibility: visible;
}
.tooltip-list{
    list-style: none;
    margin-left: -30px;
    padding-right: 10px;
    white-space: normal;
}
.vendor-name-ms{
    font-size: 0.85rem;
}